<template>
  <div class="container">
    <!-- banner -->
    <section class="banner">
      <el-carousel :autoplay="true" height="700px">
        <el-carousel-item  v-for="item in banner_list" :key="item.title">
          <img
          @click="linkPage(item.path)"
            :src="item.img_url"
            :alt="item.title"
          />
        </el-carousel-item>
       
      </el-carousel>
    </section>

    <!-- 客户场景解决方案 -->
    <section class="industry-solution">
      <!-- 模块标题 -->
      <div class="industry-solution-title">
        <h4>客户场景解决方案</h4>
      </div>

      <!-- 模块内容 -->
      <div class="industry-solution-content display_flex justify-content_space-between">
        <div
          class="industry-solution-card"
          @click="linkPage(item.path)"
          v-for="(item, index) in solution_list"
          :key="index"
        >
          <div class="industry-solution-card-img">
            <img :src="item.img_url" alt="banner" />
          </div>

          <a :href="item.path" class="industry-solution-card-text">
            <div>
              <span>{{ item.title }}</span>

              <img
                src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_home-arrow.png"
                alt="icon_箭头"
              />
            </div>
            <p>{{ item.desc }}</p>
          </a>
        </div>
      </div>
    </section>

    <!-- 柔性供应链智能化生产系统 -->
    <section class="supply-chain-system">
      <!-- 模块标题 -->
      <div class="industry-solution-title">
        <h4>柔性供应链智能化生产系统</h4>
      </div>

      <div>
        <img
          style="width: 100%"
          src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-bg_c.png"
          alt="图片-柔性供应链智能化生产系统"
        />
      </div>
    </section>

    <!-- 底部栏 -->
    <footer-bar />
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar";

export default {
  name: "Home",
  data() {
    return {
      banner_list: [
        {
          img_url: "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-banner_c.png",
          title: "个性化定制",
          path:"/",
        }, {
          img_url: "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img-fastmk-vsp.png",
          title: "闪定云采",
          path:"/product/vsp",
        }, {
          img_url: "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img-fastmk-fuli.png",
          title: "闪定企业福利",
          path:"/product/fuli",
        }
      ],
      solution_list: [
        {
          img_url:
            "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-qyfl_c.png",
          title: "企业福利",
          path: "https://www.fastmk.cn/industry-solution/welfare",
          desc: "个性化定制企业员工福利，让企业福利更有温度，更有个性，更有价值。",
        },
        {
          img_url:
            "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-lssd.png",
          title: "连锁商店",
          path: "https://www.fastmk.cn/industry-solution/store",
          desc: "品牌标识物，日常耗材自由定制，零库存。",
        },
        {
          img_url:
            "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-llbx.png",
          title: "流量变现",
          path: "https://www.fastmk.cn/industry-solution/cash",
          desc: "私域流量，企业电商，更好的个性化服务。",
        },
        {
          img_url:
            "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_home-bgwy.png",
          title: "办公文印",
          path: "https://www.fastmk.cn/industry-solution/work",
          desc: "名片、品牌展示、办公耗品，根据企业文化个性定制。",
        },
      ],
    };
  },
  components: {
    FooterBar,
  },

  methods: {
    linkPage(path) {
      location.href = path;
    },
    bannerClick() { 

    }
  },
};
</script>

<style scoped lang="scss">
.industry-solution-title {
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #16181d;
  position: relative;
  margin-top: 60px;
  text-align: center;
}

.industry-solution-title::after {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -122px;
  content: "";
  width: 153px;
  height: 19px;
  background: linear-gradient(270deg, rgba(26, 143, 255, 0) 0%, #1a8fff 100%);
  opacity: 0.5;
}

.industry-solution-content {
  width: 100%;
  padding: 0 100px;
  margin-top: 54px;
}

.industry-solution-card {
  width: 25%;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;

  .industry-solution-card-img {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .industry-solution-card-text {
    display: block;
    padding: 22px;

    span {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1a8fff;
      line-height: 37px;
      margin-bottom: 22px;
      display: inline-block;
    }

    img {
      width: 36px;
      margin-left: 20px;
      vertical-align: text-top;
    }

    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #16181d;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.industry-solution-card:hover {
  box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
  position: relative;
  top: -7px;
}

.industry-solution-content .industry-solution-card:nth-last-child(1) {
  margin: 0;
}

.supply-chain-system .industry-solution-title::after {
  margin-left: -182px;
}

@media screen and (min-width: 1600px) {
  .industry-solution-content {
    padding: 0;
  }
}
</style>
