<template>
  <div id="app">
    <!-- 导航栏 -->
    <header
      id="nav-bar-container"
      class="display_flex justify-content_space-between align-items_center"
    >
      <!-- logo -->
      <a href="/">
        <img
          src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/logo.png"
          alt="图片-闪定科技logo"
        />
      </a>

      <!-- 导航 -->
      <nav class="nav-bar">
        <a href="/"
          >首页
          <i v-if="!curTab" class="tab-line"></i>
        </a>
           <div id="nav-bar-product">
          产 品
          <i
            v-if="
              curTab == 'vsp' ||
              curTab == 'fuli'
            "
            class="tab-line"
          ></i>

          <div class="nav-bar-sub-menu nav-bar-menu">
            <a href="/product/vsp">闪定云采</a>
            <a href="/product/fuli">闪定福利</a>
          </div>
        </div>
        <div id="nav-bar-solution">
          行业解决方案

          <i
            v-if="
              curTab == 'welfare' ||
              curTab == 'store' ||
              curTab == 'cash' ||
              curTab == 'work'
            "
            class="tab-line"
          ></i>

          <div class="nav-bar-menu">
            <a href="/industry-solution/welfare">企业福利</a>
            <a href="/industry-solution/store">连锁商店</a>
            <a href="/industry-solution/cash">流量变现</a>
            <a href="/industry-solution/work">办公文印</a>
          </div>
        </div>

     

        <a href="/service-support"
          >服务与支持
          <i v-if="curTab == 'service-support'" class="tab-line"></i>
        </a>

        <a href="/about-us"
          >关于我们
          <i v-if="curTab == 'about-us'" class="tab-line"></i>
        </a>
      </nav>
    </header>

    <!-- 视图 -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      curTab: "home",
    };
  },
  mounted() {
    let arr = location.href.split("/");
    let curTabs = arr[arr.length - 1];
    // console.log("---",curTab);

    this.curTab = curTabs == "www.fastmk.cn" ? curTabs : arr[arr.length - 2];
    console.log("---",this.curTab);
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  min-width: 1024px;
  max-width: 1440px;
  margin: 0 auto;
}

#nav-bar-container {
  position: fixed;
  top: 10px;
  left: 50%;
  text-align: left;
  z-index: 999;
  margin-left: -620px;
  width: 1240px;
  height: 70px;
  line-height: 70px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  img {
    width: 130px;
    height: 27px;
    vertical-align: middle;
    margin-left: 22px;
  }

  .nav-bar {
    > a,
    > div {
      cursor: pointer;
      position: relative;
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      display: inline-block;
      color: #16181d;
      text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      margin: 0 30px;
    }
    > a:active {
      border-bottom: 2px solid #1a8fff;
      color: #16181d;
      font-weight: bold;
      font-family: PingFangSC-Medium, PingFang SC;
    }
  }

 #nav-bar-product {
    position: relative;
    line-height: 90px;

     .nav-bar-sub-menu {
  
      margin-left: -60px;
      height: 106px;
    
    }
  }

  #nav-bar-product:hover .nav-bar-menu {
    visibility: visible;
  }

  #nav-bar-solution {
    position: relative;
    line-height: 90px;

     
  }

  #nav-bar-solution:hover .nav-bar-menu {
    visibility: visible;
  }

  .nav-bar-menu {
    visibility: hidden;
    position: absolute;
    top: 84px;
    left: 0;
    margin-left: -36px;
    width: 165px;
    padding: 12px;
    height: 186px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    a {
      display: block;
      height: 40px;
      line-height: 40px;
      background: #fff;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #16181d;
      border-radius: 2px;
      cursor: pointer;
    }

    a:hover {
      background: #eef7ff;
    }

    a:active {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      background: #eef7ff;
    }
  }
}

.tab-line {
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #1a8fff;
  width: 100%;
}

#nav-bar-solution .tab-line {
  bottom: 10px;
}
</style>
