import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/home/',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '企业采购_企业级定制平台_闪定科技',
      content: {
        keywords: '闪定科技,个性定制,柔性供应链',
        description: '闪定科技致力于打造企业级柔性供应链定制平台，定制您的品牌故事，实现个性定制化服务。',
      }
    }
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue'),
    meta: {
      title: '关于我们_闪定科技',
      content: {
        keywords: '关于我们,公司简介,闪定科技',
        description: '闪定科技致力于在企业福利、营销物料、商务馈赠、宣传品、IP衍生品等场景里提供线上智能的供应链解决方案，实现个性定制化服务。',
      }
    }
  },
  {
    path: '/business-buy',
    name: 'businessBuy',
    component: () => import(/* webpackChunkName: "about" */ '../views/businessBuy.vue'),
    meta: {
      title: '企业电商化采购_闪定科技',
      content: {
        keywords: '采购数字化,采购流程,数字转型,电商化',
        description: '我们专注大中型企业电商化采购综合解决方案，通过SaaS应用，建立企业采购标准化，数字化，为企业带来多重价值，搭建售前、实施、售后各个任务模块团队，进行项目过程全服务。',
      }
    }
  },
  {
    path: '/industry-solution/welfare',
    name: 'welfare',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/welfare.vue'),
    meta: {
      title: '企业福利_行业解决方案_闪定科技',
      content: {
        keywords: '企业福利,定制,采购,供应链',
        description: '闪定科技提供的四大行业解决方案之一，个性化定制企业员工福利，让企业福利更有温度，更有个性，更有价值。',
      }
    }
  },
  {
    path: '/industry-solution/store',
    name: 'store',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/store.vue'),
    meta: {
      title: '连锁商店_行业解决方案_闪定科技',
      content: {
        keywords: '连锁商店,定制,采购,供应链',
        description: '闪定科技提供的四大行业解决方案之一，品牌标识物，日常耗材自由定制，零库存。',
      }
    }
  },
  {
    path: '/industry-solution/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/cash.vue'),
    meta: {
      title: '流量变现_行业解决方案_闪定科技',
      content: {
        keywords: '流量变现,定制,采购,供应链',
        description: '闪定科技提供的四大行业解决方案之一，私域流量，企业电商，更好的个性化服务。',
      }
    }
  },

  {
    path: '/industry-solution/work',
    name: 'work',
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/work.vue'),
    meta: {
      title: '办公文印_行业解决方案_闪定科技',
      content: {
        keywords: '办公文印,定制,采购,供应链',
        description: '闪定科技提供的四大行业解决方案之一，名片、品牌展示、办公耗品，根据企业文化个性定制。',
      }
    }
  },
  {
    path: '/service-support',
    name: 'serviceSupport',
    component: () => import(/* webpackChunkName: "about" */ '../views/serviceSupport.vue'),
    meta: {
      title: '服务与支持_闪定科技',
      content: {
        keywords: '柔性供应链,快速支付,定制,服务支持',
        description: '闪定科技为客户提供便捷快速的支付，建立了面向客户的高效协同运作系统。交付团队全程参与解决方案的制定，保障快速交付。公司的信息和自动化管理，一体化解决方案、区域化网店运营、完备的物流配送等，为快速响应客户需求并最短时间交付产品提供了坚实的保障。',
      }
    }
  },
  {
    path: '/product/vsp',
    name: 'productVsp',
    component: () => import('../views/product/vsp.vue'),
    meta: {
      title: '产品-闪定云采',
      content: {
        keywords: '电商化采购,云采,阳光商城,服务支持',
        description: '闪定科技为客户提供便捷快速的支付，建立了面向客户的高效协同运作系统。交付团队全程参与解决方案的制定，保障快速交付。公司的信息和自动化管理，一体化解决方案、区域化网店运营、完备的物流配送等，为快速响应客户需求并最短时间交付产品提供了坚实的保障。',
      }
    }
  },
  {
    path: '/product/fuli',
    name: 'productFuli',
    component: () => import('../views/product/fuli.vue'),
    meta: {
      title: '产品-闪定云采',
      content: {
        keywords: '电商化采购,云采,阳光商城,服务支持',
        description: '闪定科技为客户提供便捷快速的支付，建立了面向客户的高效协同运作系统。交付团队全程参与解决方案的制定，保障快速交付。公司的信息和自动化管理，一体化解决方案、区域化网店运营、完备的物流配送等，为快速响应客户需求并最短时间交付产品提供了坚实的保障。',
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
