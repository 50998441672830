import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/index.scss' // global css


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import animated from 'animate.css'

import { Carousel, CarouselItem, Divider, Image } from 'element-ui'
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Divider)
Vue.use(Image)
Vue.use(VueAwesomeSwiper)
Vue.use(animated)


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
