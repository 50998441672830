var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('header',{staticClass:"display_flex justify-content_space-between align-items_center",attrs:{"id":"nav-bar-container"}},[_vm._m(0),_c('nav',{staticClass:"nav-bar"},[_c('a',{attrs:{"href":"/"}},[_vm._v("首页 "),(!_vm.curTab)?_c('i',{staticClass:"tab-line"}):_vm._e()]),_c('div',{attrs:{"id":"nav-bar-product"}},[_vm._v(" 产 品 "),(
            _vm.curTab == 'vsp' ||
            _vm.curTab == 'fuli'
          )?_c('i',{staticClass:"tab-line"}):_vm._e(),_vm._m(1)]),_c('div',{attrs:{"id":"nav-bar-solution"}},[_vm._v(" 行业解决方案 "),(
            _vm.curTab == 'welfare' ||
            _vm.curTab == 'store' ||
            _vm.curTab == 'cash' ||
            _vm.curTab == 'work'
          )?_c('i',{staticClass:"tab-line"}):_vm._e(),_vm._m(2)]),_c('a',{attrs:{"href":"/service-support"}},[_vm._v("服务与支持 "),(_vm.curTab == 'service-support')?_c('i',{staticClass:"tab-line"}):_vm._e()]),_c('a',{attrs:{"href":"/about-us"}},[_vm._v("关于我们 "),(_vm.curTab == 'about-us')?_c('i',{staticClass:"tab-line"}):_vm._e()])])]),_c('router-view')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":"https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/logo.png","alt":"图片-闪定科技logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-bar-sub-menu nav-bar-menu"},[_c('a',{attrs:{"href":"/product/vsp"}},[_vm._v("闪定云采")]),_c('a',{attrs:{"href":"/product/fuli"}},[_vm._v("闪定福利")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-bar-menu"},[_c('a',{attrs:{"href":"/industry-solution/welfare"}},[_vm._v("企业福利")]),_c('a',{attrs:{"href":"/industry-solution/store"}},[_vm._v("连锁商店")]),_c('a',{attrs:{"href":"/industry-solution/cash"}},[_vm._v("流量变现")]),_c('a',{attrs:{"href":"/industry-solution/work"}},[_vm._v("办公文印")])])}]

export { render, staticRenderFns }