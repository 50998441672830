<template>
  <footer>
    <div class="company-info-container">
      <div class="company-info" :class="{ border_top: !border_hide }">
        <div class="company-info-slogan">
          企业级柔性供应链定制平台，定制您的品牌故事
        </div>

        <div class="display_flex justify-content_space-between">
          <div class="company-info-text display_flex">
            <div>
              <span>加入我们</span>
              <span>hr@fastmk.cn</span>
            </div>

            <div>
              <span>商务邮箱</span>
              <span>market@fastmk.cn</span>
            </div>
            <div>
              <span>联系电话</span>
              <span>021 - 6218 7739</span>
            </div>

            <div>
              <span>联系我们</span>
              <span>上海市长宁区金钟路968号5号楼805室</span>
            </div>
          </div>

          <div class="company-info-pic">
            <div>
              <img
                src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon-h5.png"
                alt="picture"
              />
              <span>H5</span>

              <!-- H5-二维码 -->
              <div class="company-info-pic-hover">
                <img
                  src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_qrcode-h5.png"
                  alt="H5二维码"
                />
              </div>
            </div>

            <div>
              <img
                src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon-wechat.png"
                alt="picture"
              />
              <span>公众号</span>

              <!-- 公众号-二维码 -->
              <div class="company-info-pic-hover">
                <img
                  src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_qrcode_gzh2.png"
                  alt="公众号二维码"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="company-copyright">
        <div>Copyright © 2021 上海闪定科技有限公司</div>

        <a
          style="padding"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502005823"
          target="_blank"
        >
          <div style="padding-left: 10px">
            <img src="../image/gongan.png" /> 沪公网安备 31010502005823号
          </div>
        </a>

        <div style="padding-left: 10px">
          <a
            href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E6%B2%AAB2-20211396&type=xuke"
            target="_blank"
            >ICP证：沪B2-20211396</a
          >
        </div>
        <div style="padding-left: 10px">
          <a
            href="https://beian.miit.gov.cn/?spm=5176.19720258.J_9220772140.115.e9392c4a0Z6bBN#/Integrated/index"
            target="_blank"
            >备案号：沪ICP备2021001847号</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    border_hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.border_top {
  border-top: 1px solid #b0b6c5;
  padding-top: 48px;
}

.company-info-container {
  width: 100%;
  padding: 0 100px;

  .company-info {
    flex-wrap: nowrap;

    .company-info-slogan {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca7c2;
      line-height: 28px;
      letter-spacing: 10px;
      margin-bottom: 60px;
    }
  }
}

footer {
  width: 100%;
  padding: 50px 0 30px 0;
}

.company-info-text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6a7181;
  line-height: 20px;

  div {
    margin-right: 50px;
  }

  div span:nth-child(2) {
    font-size: 20px;
    display: block;
    margin-top: 20px;
    font-family: PingFang SC;
    color: #666;
  }
}

.company-info-pic {
  > div {
    text-align: center;
    margin-right: 44px;
    display: inline-block;
    position: relative;

    > img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      margin-bottom: 12px;
      display: block;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6a7181;
      line-height: 20px;
    }
  }

  div:nth-last-child(1) {
    margin: 0;
  }
}

.company-info-pic > div:hover .company-info-pic-hover {
  visibility: visible;
}

.company-info-pic-hover {
  visibility: hidden;
  position: absolute;
  top: -178px;
  left: -44px;
  background-color: #fff;
  width: 154px;
  height: 154px;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 5px 20px 0px rgba(29, 51, 107, 0.1);

  img {
    width: 100%;
  }
}

.company-info-pic-hover::after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -12px;
  bottom: -24px;
  width: 0;
  height: 0;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.company-copyright {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6a7181;
  line-height: 20px;
  margin-top: 42px;

  a:hover {
    color: rgb(90, 147, 255);
  }
}

@media screen and (min-width: 1600px) {
  .company-info-container {
    padding: 0;
  }
}
</style>
